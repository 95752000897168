import React, { useEffect, useState } from "react"

import SEO from "../components/seo"
import { FaArrowRight, FaEnvelope } from 'react-icons/fa'

import "../scss/contactus.scss"
import "../scss/contactform.scss"

import EmailIcon from "../assets/img/youpalGroup/contactUs/icon1.png"
import CallIcon from "../assets/img/youpalGroup/contactUs/icon2.png"
import SupportIcon from "../assets/img/youpalGroup/contactUs/icon3.png"

const ContactUsPage = () => {

  return (
    <div>
      <SEO title="Contact us" keywords={[`youpal`, `youpal group`]} />
      <section className="container contactUsPageSection">
        <h2>Reach Out</h2>
        <p className="contactUsSubtitle">
          Our team of amazing and awesome individuals is always ready to give
          you answers to your questions.
        </p>
        <div className="formDiv">
          {/* Mautic form */}
          <div id="mauticform_wrapper_youpalnewform" className="mauticform_wrapper">
              <form autoComplete="false" role="form" method="post" action="https://ma.youpal.se/form/submit?formId=33" id="mauticform_youpalnewform" data-mautic-form="youpalnewform" encType="multipart/form-data">
                  <div className="mauticform-error" id="mauticform_youpalnewform_error"></div>
                  <div className="mauticform-message" id="mauticform_youpalnewform_message"></div>
                  <div className="mauticform-innerform">

                      
                    <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

                      <label id="mauticform_label_youpalnewform_first_name" className="bigLabel reqLabel">Full Name</label>
                      <div className="oneBigRow">
                        <div id="mauticform_youpalnewform_first_name" data-validate="first_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                            <input id="mauticform_input_youpalnewform_first_name" name="mauticform[first_name]" defaultValue="" className="mauticform-input" type="text" />
                            <label id="mauticform_label_youpalnewform_first_name" htmlFor="mauticform_input_youpalnewform_first_name" className="mauticform-label"><span>First Name</span><span className="mauticform-errormsg" style={{ display: 'none' }}>This is required.</span></label>
                        </div>

                        <div id="mauticform_youpalnewform_last_name" className="mauticform-row mauticform-text mauticform-field-2">
                            <input id="mauticform_input_youpalnewform_last_name" name="mauticform[last_name]" defaultValue="" className="mauticform-input" type="text" />
                            <label id="mauticform_label_youpalnewform_last_name" htmlFor="mauticform_input_youpalnewform_last_name" className="mauticform-label"><span>Last Name</span><span className="mauticform-errormsg" style={{ display: 'none' }}></span></label>
                        </div>
                      </div>

                      <label id="mauticform_label_youpalnewform_email" className="bigLabel reqLabel">E-mail</label>
                      <div id="mauticform_youpalnewform_email" data-validate="email" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-3 mauticform-required">
                          <input id="mauticform_input_youpalnewform_email" name="mauticform[email]" defaultValue="" className="mauticform-input" type="email" />
                          <label id="mauticform_label_youpalnewform_email" htmlFor="mauticform_input_youpalnewform_email" className="mauticform-label"><span>example@example.com</span><span className="mauticform-errormsg" style={{ display: 'none' }}>This is required.</span></label>
                      </div>

                      <label id="mauticform_label_youpalnewform_mobile" className="bigLabel reqLabel">Mobile Number</label>
                      <div id="mauticform_youpalnewform_mobile" data-validate="mobile" data-validation-type="tel" className="mauticform-row mauticform-tel mauticform-field-4 mauticform-required">
                          <input id="mauticform_input_youpalnewform_mobile" name="mauticform[mobile]" defaultValue="" className="mauticform-input" type="tel" placeholder="(000) 000-0000" />
                          <label id="mauticform_label_youpalnewform_mobile" htmlFor="mauticform_input_youpalnewform_mobile" className="mauticform-label"><span>Please enter a valid phone number</span><span className="mauticform-errormsg" style={{ display: 'none' }}>This is required.</span></label>
                          
                      </div>

                      <label id="mauticform_label_youpalnewform_how_we_can_help_you" className="bigLabel">How we can help you?</label>
                      <div id="mauticform_youpalnewform_how_we_can_help_you" className="mauticform-row mauticform-text mauticform-field-5">
                          <textarea id="mauticform_input_youpalnewform_how_we_can_help_you" name="mauticform[how_we_can_help_you]" className="mauticform-textarea" placeholder="Type here..."></textarea>
                          <span className="mauticform-errormsg" style={{ display: 'none' }}></span>
                      </div>

                      <div id="mauticform_youpalnewform_private_policy_checkbox" data-validate="private_policy_checkbox" data-validation-type="checkboxgrp" className="mauticform-row mauticform-checkboxgrp mauticform-field-6 mauticform-required">
                          <div className="mauticform-checkboxgrp-row">
                              <label id="mauticform_checkboxgrp_label_private_policy_checkbox_10" htmlFor="mauticform_checkboxgrp_checkbox_private_policy_checkbox_10" className="mauticform-checkboxgrp-label reqLabel">
                                  <input className="mauticform-checkboxgrp-checkbox" name="mauticform[private_policy_checkbox][]" id="mauticform_checkboxgrp_checkbox_private_policy_checkbox_10" type="checkbox" defaultValue="1" />
                                  I consent to use tracking/marketing cookies and to my personal data being stored and processed in accordance with
                              </label>
                          </div>
                          <label id="mauticform_label_youpalnewform_mobile" htmlFor="mauticform_input_youpalnewform_mobile" className="mauticform-label"><span></span><span className="mauticform-errormsg" style={{ display: 'none' }}>This is required.</span></label>
                      </div>

                      <div id="mauticform_youpalnewform_private_policy_aggrement" className="mauticform-row mauticform-freehtml mauticform-field-7">
                          <div id="mauticform_input_youpalnewform_private_policy_aggrement" name="mauticform[private_policy_aggrement]" defaultValue="" className="mauticform-freehtml">
                              <a href="/privacy-policy" className="italic">Privacy Policy</a>
                          </div>
                      </div>

                      <div id="mauticform_youpalnewform_i_am_not_a_robot" style={{ display: 'none' }} className="mauticform-row mauticform-text mauticform-field-8">
                          <label id="mauticform_label_youpalnewform_i_am_not_a_robot" htmlFor="mauticform_input_youpalnewform_i_am_not_a_robot" className="mauticform-label">I am not a robot</label>
                          <input id="mauticform_input_youpalnewform_i_am_not_a_robot" name="mauticform[i_am_not_a_robot]" defaultValue="" className="mauticform-input" type="text" />
                          <span className="mauticform-errormsg" style={{ display: 'none' }}></span>
                      </div>

                      <div id="mauticform_youpalnewform_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-6">
                          <button type="submit" name="mauticform[submit]" id="mauticform_input_youpalnewform_submit" defaultValue="" className="mauticform-button btn btn-default">Submit</button>
                      </div>
                    </div>
                  </div>

                  <input type="hidden" name="mauticform[formId]" id="mauticform_youpalnewform_id" defaultValue="33" />
                  <input type="hidden" name="mauticform[return]" id="mauticform_youpalnewform_return" defaultValue="" />
                  <input type="hidden" name="mauticform[formName]" id="mauticform_youpalnewform_name" defaultValue="youpalnewform" />

              </form>
          </div>
        </div>
      </section>

      <section className="container contactsSection contactsSectiont">
        <div className="row">
          <div className="col iconP">
            <img src={EmailIcon} />
            <h3>Email us</h3>
            <p>
              If you have any additional or urgent queries reach us on the
              following email:
            </p>
            <p className="plink">
              <a href="mailto:request@youpalgroup.com">request@youpalgroup.com</a>
            </p>
          </div>
          <div className="col iconP">
            <img src={CallIcon} />
            <h3>Call us</h3>
            <p>
              If you really want to hear our voice, please reach out to the
              following number:
            </p>
            <p className="plink"><a href="tel:+4681-241-1707">+46 8-124 117 07</a></p>
          </div>
          <div className="col iconP">
            <img src={SupportIcon} />
            <h3>Support</h3>
            <p>
              You can also get lucky via contacting one of our amazing team
              members via live chat
            </p>
            <p className="plink" onClick={() => {
                RocketChat(function () {
                  this.maximizeWidget();
                });
              }}>
                <span style={{ cursor: 'pointer' }}>
                  <span style={{ marginRight: "20px" }}>Go chatting</span>
                  <FaArrowRight style={{ fill: "#473BF0" }} />
                </span>
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUsPage
